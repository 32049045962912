<template>
  <v-dialog v-model="showModal" scrollable max-width="733px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <div class="text-h6 ml-2 pa-2">Publicar Proyecto</div>
        <div class="roboto ml-2 mb-2 pa-2">
          Para la publicación de este proyecto, necesitamos que indiques y declares lo siguiente:
        </div>
        <v-card-text>
          <div class="modal-title pa-0 ma-0">Fecha de período de Postulación</div>
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="fechaInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="tway-violet"
                    :value="formatDate(publicationPeriod.startDate)"
                    label="Fecha de Inicio"
                    :rules="[(v) => !!v || 'Campo Fecha de Inicio es requerido']"
                    :error-messages="errorPublicationDateStart()"
                    placeholder="dd-mm-aaaa"
                    outlined
                    autocomplete="off"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                  />
                </template>
                <v-date-picker
                  @change="errorDate()"
                  :min="initialDate"
                  color="tway-violet"
                  v-model="publicationPeriod.startDate"
                  :first-day-of-week="1"
                  @input="fechaInicio = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="fechaTermino"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="tway-violet"
                    :value="formatDate(publicationPeriod.endDate)"
                    label="Fecha de Término"
                    :rules="[required('Fecha de Término')]"
                    :error-messages="errorPublicationDateEnd()"
                    placeholder="dd-mm-aaaa"
                    outlined
                    autocomplete="off"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                  />
                </template>
                <v-date-picker
                  @change="errorDate()"
                  color="tway-violet"
                  :min="publicationPeriod.startDate ? publicationPeriod.startDate : initialDate"
                  v-model="publicationPeriod.endDate"
                  :first-day-of-week="1"
                  @input="fechaTermino = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <div class="modal-title mr-2">Fecha de período de Licitación</div>
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="fechaInicioLic"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="tway-violet"
                    :value="formatDate(biddingPeriod.startDate)"
                    label="Fecha de Inicio"
                    :rules="[required('Fecha de Inicio')]"
                    :error-messages="errorBiddingDateStart()"
                    placeholder="dd-mm-aaaa"
                    outlined
                    autocomplete="off"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                  />
                </template>
                <v-date-picker
                  @change="errorDate()"
                  :min="initialDate"
                  color="tway-violet"
                  v-model="biddingPeriod.startDate"
                  :first-day-of-week="1"
                  @input="fechaInicioLic = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="fechaTerminoLic"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="tway-violet"
                    :value="formatDate(biddingPeriod.endDate)"
                    label="Fecha de Término"
                    :rules="[required('Fecha de Término')]"
                    :error-messages="errorBiddingDateEnd()"
                    placeholder="dd-mm-aaaa"
                    outlined
                    autocomplete="off"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                  />
                </template>
                <v-date-picker
                  @change="errorDate()"
                  :min="biddingPeriod.startDate ? biddingPeriod.startDate : initialDate"
                  color="tway-violet"
                  v-model="biddingPeriod.endDate"
                  :first-day-of-week="1"
                  @input="fechaTerminoLic = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <div class="modal-title mr-2">Fecha de Resultados</div>
          <v-row>
            <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="fechaResultados"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="tway-violet"
                    :value="formatDate(dateOfResults)"
                    label="Fecha de resultados"
                    :rules="[required('Fecha de resultados')]"
                    placeholder="dd-mm-aaaa"
                    outlined
                    autocomplete="off"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                  />
                </template>
                <v-date-picker
                  @change="errorDate()"
                  :min="initialDate"
                  color="tway-violet"
                  v-model="dateOfResults"
                  :first-day-of-week="1"
                  @input="fechaResultados = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <div class="modal-title pa-0 ma-0">Declaraciones</div>
          <v-checkbox color="success" v-model="declaration">
            <template v-slot:label>
              <div>
                <a class="checkbox-modal">
                  Declaro que la información entregada es verídica y se ajusta a las necesidades de la empresa.
                  Cualquier información errónea o imprecisa será de exclusiva responsabilidad del declarante.
                </a>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox color="success" v-model="declaration2">
            <template v-slot:label>
              <div>
                <a class="checkbox-modal">
                  Autorizo a Tway para que la información y los datos proporcionados en este formulario sean tratados y
                  compartidos en el proceso de licitación de los planes Business y Best Fit. Tway no responderá frente
                  al tratamiento de la información que puedan realizar terceros.
                </a>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox color="success" v-model="acceptTermsAndConditions">
            <template v-slot:label>
              <div>
                <a @click.stop="modalTerms = true" target="_blank" class="violet-text text-decoration-underline">
                  Acepto los términos y condiciones de uso
                </a>
              </div>
            </template>
          </v-checkbox>
          <v-dialog class="modal-dialog modal-sm" v-model="modalTerms" :max-width="desktop ? '714' : '343'">
            <v-card>
              <div class="text-h6 ml- pa-5">Términos y condiciones de uso</div>

              <v-card-text>
                <iframe
                  class="iframe"
                  src="https://static-dev.twaydigital.com/terms/app/TerminosCondiciones.pdf"
                  :height="desktop ? '350' : '400'"
                  :width="desktop ? '670' : '300'"
                />
              </v-card-text>

              <v-card-actions>
                <v-row justify="center" class="ma-0 pa-0">
                  <a class="tway-violet--text ma-2 pa-2 pr-15 roboto" v-if="desktop" @click.stop="modalTerms = false">
                    VOLVER
                  </a>
                  <v-btn
                    v-if="desktop"
                    large
                    rounded
                    color="tway-violet"
                    class="white--text ma-2 roboto"
                    @click.stop="modalTerms = false"
                  >
                    ACEPTAR
                  </v-btn>
                </v-row>
                <v-row v-if="!desktop" justify="center">
                  <v-row justify="center" class="ma-0 pa-0">
                    <a class="tway-violet--text pa-3 pr-5 roboto" @click.stop="modalTerms = false"> VOLVER </a>
                    <v-btn
                      x-large
                      rounded
                      color="tway-violet"
                      class="white--text roboto"
                      @click.stop="modalTerms = false"
                    >
                      ACEPTAR
                    </v-btn>
                  </v-row>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn color="deep-purple accent-4" class="roboto text-right roboto mr-6" text @click="cancel">
              CANCELAR
            </v-btn>
            <v-btn
              large
              rounded
              color="tway-violet"
              class="elevation-2 pa-4 white--text roboto"
              :disabled="!canSubmit"
              @click="publish()"
            >
              PUBLICAR PROYECTO
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ValidatorsService from "@/services/validatorsService";

export default {
  name: "PublicationModal",
  props: {
    showModal: Boolean,
    initialDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      modalCreated: false,
      isLoading: true,
      fechaInicio: false,
      fechaTermino: false,
      fechaInicioLic: false,
      fechaTerminoLic: false,
      fechaResultados: false,
      publicationPeriod: {
        startDate: "",
        endDate: "",
      },
      biddingPeriod: {
        startDate: "",
        endDate: "",
      },
      dateOfResults: "",
      acceptTermsAndConditions: false,
      declaration: false,
      declaration2: false,
      modalTerms: false,
    };
  },
  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),
    errorDate: function () {
      if (
        this.publicationPeriod.startDate > this.publicationPeriod.endDate &&
        this.publicationPeriod.startDate != "" &&
        this.publicationPeriod.endDate != ""
      ) {
        this.errorPublicationStartDate = true;
      } else {
        this.errorPublicationStartDate = false;
      }

      if (
        this.publicationPeriod.endDate > this.biddingPeriod.startDate &&
        this.publicationPeriod.endDate != "" &&
        this.biddingPeriod.startDate != ""
      ) {
        this.errorPublicationFinishDate = true;
      } else {
        this.errorPublicationFinishDate = false;
      }

      if (
        this.biddingPeriod.startDate > this.biddingPeriod.endDate &&
        this.biddingPeriod.startDate != "" &&
        this.biddingPeriod.endDate != ""
      ) {
        this.errorBiddingStartDate = true;
      } else {
        this.errorBiddingStartDate = false;
      }

      if (
        this.biddingPeriod.endDate > this.dateOfResults &&
        this.biddingPeriod.endDate != "" &&
        this.dateOfResults != ""
      ) {
        this.errorBiddingFinishDate = true;
      } else {
        this.errorBiddingFinishDate = false;
      }
    },
    errorPublicationDateStart() {
      if (this.errorPublicationStartDate) {
        return "Fecha de Inicio no puede ser mayor a Fecha de Término";
      }
    },
    errorPublicationDateEnd() {
      if (this.errorPublicationFinishDate) {
        return "Fecha de Publicación no puede ser mayor a la fecha de Licitación";
      }
    },
    errorBiddingDateStart() {
      if (this.errorBiddingStartDate) {
        return "Fecha de Inicio no puede ser mayor a Fecha de Término";
      }
    },
    errorBiddingDateEnd() {
      if (this.errorBiddingFinishDate) {
        return "Fecha de Licitación no puede ser mayor a la Publicación de resultados";
      }
    },
    cancel() {
      this.refresh();
      this.$emit("cancel");
    },
    formatDate(date) {
      if (date) {
        let splited = date.split("-");
        return `${splited[2]}-${splited[1]}-${splited[0]}`;
      }
      return null;
    },
    refresh() {
      this.$refs.form.reset();
      this.publicationPeriod.startDate = "";
      this.publicationPeriod.endDate = "";
      this.biddingPeriod.startDate = "";
      this.biddingPeriod.endDate = "";
      this.dateOfResults = "";
    },
    publish() {
      this.$emit("publish", {
        publicationPeriod: this.publicationPeriod,
        biddingPeriod: this.biddingPeriod,
        dateOfResults: this.dateOfResults,
      });
    },
  },
  computed: {
    canSubmit() {
      return (
        this.acceptTermsAndConditions &&
        this.declaration &&
        this.declaration2 &&
        this.valid &&
        !this.errorPublicationStartDate &&
        !this.errorPublicationFinishDate &&
        !this.errorBiddingStartDate &&
        !this.errorBiddingFinishDate
      );
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style scoped>
.violet-text {
  color: #7319d5 !important;
}
.main-img {
  margin-top: 10vh;
}
.my-projects {
  text-align: center;
}
.tway-header-1 {
  font-weight: 400;
}
.description-test {
  font-weight: 300 !important;
  line-height: 25px !important;
  font-size: 12pt;
  font-family: "Roboto Condensed", sans-serif !important;
}
.t-tway {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
.modal-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #333333;
}
.tab-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
}
.checkbox-modal {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}
.card-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #333333;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
}
.border {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 3px 3px 8px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 0px 0px;
}
.bgc {
  background: #e8f5e9;
  border-radius: 4px;
}
.byc {
  background: #fff3e0;
  border-radius: 4px;
}
.brp {
  background: #ffebee;
  border-radius: 4px;
}
.bpp {
  background: #f3e5f5;
  border-radius: 4px;
}
.status-lic {
  position: static;
  left: 3.48%;
  right: 3.48%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #7319d5;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.letter {
  position: static;
  left: 3.11%;
  right: 3.11%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #b71c1c;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.status {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f57f17;

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}
.status-complete {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  color: #16c62e;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}

.resumen-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 72px;
  overflow: auto;
  color: #999999;
}
.border-button-violet {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px !important;
}
.v-text-field--outlined fieldset {
  border-color: #7319d5 !important;
}
</style>
