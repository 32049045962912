<template>
  <div class="create-project-form">
    <Loader :visible="loading" />
    <v-row>
      <v-col offset-md="2" md="8" sm="12">
        <div class="text-h5 text-md-h4 text-center mb-4 form-title tway-gray-dark--text">Descripción de Proyecto</div>
        <div class="mb-4">
          <v-stepper class="elevation-0" v-model="stepLabel" alt-labels>
            <v-stepper-header class="pl-0 pr-0">
              <v-stepper-step class="mr-md-8 text-center" step="1" color="tway-violet" :complete="stepLabel > 1">
                <span style="text-align: center; width: 100%">Antecedentes Generales</span>
              </v-stepper-step>
              <v-divider :color="stepLabel > 1 ? 'tway-violet' : null" />
              <v-stepper-step
                class="mr-md-8 ml-md-8 text-center"
                step="2"
                color="tway-violet"
                :complete="stepLabel > 2"
              >
                <span style="text-align: center; width: 100%">Otros Datos</span>
              </v-stepper-step>
              <v-divider :color="stepLabel > 2 ? 'tway-violet' : null" />
              <v-stepper-step
                class="mr-md-8 ml-md-8 text-center"
                step="3"
                color="tway-violet"
                :complete="stepLabel > 3"
              >
                <span style="text-align: center; width: 100%">Requisitos Técnicos</span>
              </v-stepper-step>
              <v-divider :color="stepLabel > 3 ? 'tway-violet' : null" />
              <v-stepper-step class="ml-md-8 text-center" step="4" color="tway-violet" :complete="stepLabel > 4">
                <span style="text-align: center; width: 100%">Confirmación</span>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <router-view />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 mb-5">
      <v-col offset-md="2" md="8" offset-sm="0" class="d-flex justify-space-between align-center">
        <div class="d-inline">
          <v-btn
            v-if="project.status.id < 3"
            :disabled="stepIndex == 0"
            large
            depressed
            rounded
            class="roboto elevation-1"
            outlined
            color="deep-purple accent-4"
            @click="back"
          >
            Atras
          </v-btn>
        </div>
        <div class="d-inline roboto mr-2">
          <v-btn
            v-if="project.status.id < 3"
            class="text-uppercase text-body-1 roboto lower"
            rounded
            text
            x-small
            color="deep-purple accent-4"
            @click="exitAndSave"
          >
            GUARDAR CAMBIOS
          </v-btn>
          <v-btn
            v-if="isLastStep(stepIndex) && project.status.id >= 3"
            class="button-text white--text"
            rounded
            depressed
            color="deep-purple accent-4"
            @click="back"
          >
            VOLVER A MIS PROYECTOS
          </v-btn>
        </div>
        <div class="d-inline">
          <v-btn
            v-if="isLastStep(stepIndex) && project.status.id < 3"
            large
            depressed
            rounded
            :disabled="!formCompleted"
            class="tway-violet white--text roboto elevation-3 text-upper"
            @click="showModal = true"
          >
            publicar proyecto
          </v-btn>
          <v-btn
            v-if="!isLastStep(stepIndex) && project.status.id < 3"
            large
            depressed
            rounded
            class="tway-violet white--text roboto elevation-3"
            @click="next"
          >
            Siguiente
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <publication-modal
      :initial-date="project.creationDate ? project.creationDate.split('T')[0] : new Date().toISOString().split('T')[0]"
      :show-modal="showModal"
      @cancel="showModal = false"
      @publish="exitAndPublish"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CallForTenderService from "@/services/callForTenderService";
import store from "@/store";
import PublicationModal from "@/components/projects/PublicationModal";
import Loader from "@/components/Loader";

export default {
  name: "CreateProject",
  components: {
    PublicationModal,
    Loader,
  },
  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    let index = to.meta.step;
    if (!this.stepIsLocked(index)) {
      this.stepIndex = index;
      next();
      window.scrollTo(0, 0);
    } else {
      next({
        path: index > 0 ? this.getStep(index - 1).path : "GeneralData",
        replace: true,
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    let index = to.meta.step;
    let id = to.params.projectId;
    let project = store.getters["createProject/getProject"];
    if (!project || project.id !== id) {
      CallForTenderService.getProject(id)
        .then((p) => {
          if (p) {
            store.dispatch("createProject/setFormDataFromProject", p);
            let lastStep = store.getters["createProject/getLastComplete"];
            next((vm) => {
              vm.stepIndex = index;
              next(lastStep.step.path);
            });
          } else {
            next({ name: "project-foundation" });
          }
        })
        .catch((err) => {
          console.log(err);
          next({ name: "project-foundation" });
        });
    } else if (index !== 0) {
      next({ name: "create-project" });
    } else {
      next();
    }
  },
  destroyed() {
    this.resetAll();
  },
  data() {
    return {
      stepIndex: 0,
      showModal: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      resetAll: "createProject/resetAll",
      updateProject: "createProject/updateProject",
      unlockStep: "createProject/unlockStep",
    }),
    next() {
      this.updateProject().then(() => {
        CallForTenderService.createProject(this.project).then(() => {
          if (
            (this.project.technicalRequirements && this.project.technicalRequirements.changed) ||
            (this.project.aditionalAttachment && this.project.aditionalAttachment.changed)
          ) {
            CallForTenderService.addAttachments(
              this.project.id,
              this.project.technicalRequirements,
              this.project.aditionalAttachment
            ).then(() => {
              window.scrollTo(0, 0);
              this.unlockStep(this.stepIndex + 1);
              this.$router.push(this.getStep(this.stepIndex + 1).path);
            });
          } else {
            window.scrollTo(0, 0);
            this.unlockStep(this.stepIndex + 1);
            this.$router.push(this.getStep(this.stepIndex + 1).path);
          }
        });
      });
    },
    back() {
      if (this.project.status.id < 3) {
        let prevStep = this.stepIndex - 1;
        if (prevStep >= 0) {
          this.$router.push(this.getStep(prevStep).path);
        }
      } else {
        this.$router.push({ name: "my-projects" });
      }
    },
    exitAndSave() {
      this.loading = true;
      this.updateProject().then(() => {
        CallForTenderService.createProject(this.project)
          .then(() => {
            if (
              (this.project.technicalRequirements && this.project.technicalRequirements.changed) ||
              (this.project.aditionalAttachment && this.project.aditionalAttachment.changed)
            ) {
              CallForTenderService.addAttachments(
                this.project.id,
                this.project.technicalRequirements,
                this.project.aditionalAttachment
              )
                .then(() => this.$router.push({ name: "my-projects" }))
                .finally(() => (this.loading = false));
            } else {
              this.$router.push({ name: "my-projects" });
            }
          })
          .finally(() => (this.loading = false));
      });
    },
    exitAndPublish(modalData) {
      this.updateProject()
        .then(() => {
          CallForTenderService.createProject(this.project).then((saved) => {
            if (this.project.technicalRequirements && this.project.technicalRequirements.changed) {
              CallForTenderService.addAttachments(
                this.project.id,
                this.project.technicalRequirements,
                this.project.aditionalAttachment
              ).then((savedWithAttch) => {
                CallForTenderService.publishProject({ ...savedWithAttch, ...modalData })
                  .then(() =>
                    this.$router.push({
                      name: "my-projects/created",
                      params: { projectId: savedWithAttch.id },
                    })
                  )
                  .finally(() => (this.loading = false));
              });
            } else {
              CallForTenderService.publishProject({ ...saved, ...modalData })
                .then(() =>
                  this.$router.push({
                    name: "my-projects/created",
                    params: { projectId: saved.id },
                  })
                )
                .finally(() => (this.loading = false));
            }
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapGetters({
      stepIsLocked: "createProject/getStepIsLocked",
      stepIsComplete: "createProject/getStepIsComplete",
      getStep: "createProject/getStep",
      isLastStep: "createProject/getIsLastStep",
      formCompleted: "createProject/getIsAllComplete",
      project: "createProject/getProject",
    }),
    stepLabel() {
      return this.stepIndex + 1;
    },
  },
};
</script>

<style scoped>
.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}
.form-title {
  font-size: 19pt !important;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-family: "Roboto";
}
</style>

<style>
.create-project-form .v-messages__message {
  color: red !important;
}
</style>
